#player {
	position: fixed;
	height: 50px;
	background-color: black;
	color: white;
	font-family: ar-bonnie;
	font-size: 28px;
	bottom: 0;
	width: 100%;
  left: 0;
  display: flex;
  /*justify-content: space-evenly;*/
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

#player span {
	line-height: inherit;
}

.glyphicon {
	font-size: 20px;
}

#volume {
	display: inline-block;
	/*width: 120px;*/
	/*margin-left: 20px;*/
	display: none;
}

#progress {
	display: inline-block;
	width: 60%;
	margin-left: 10px;
}

.control {
	/*width: 40px;*/
	text-align: center;
}

#left {
	/*margin-left: 100px;*/
}

#current_time {
	/*margin-left: 0px;*/
}

.ib {
	display: inline-block;
}

#track {
	display: flex;
	flex-direction: row;
	align-items: center;
	min-width: 150px;
	width: 300px;
}

#library {
	/*margin-left: 20px;*/
}

#controls {
	display: flex;
	flex-direction: row;
	width: 100px;
	justify-content: space-evenly;
}

#info {
	flex-grow: 1;
	flex-wrap: nowrap;
	text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 5px;
  text-align: center;
}


@media (max-width: 400px) {
  #track {
  	display: none;
  }
  #info {
  	font-size: 18px !important;
  }
  #player {
  	padding-left: 0px;
  	padding-right: 0px;
  }
}