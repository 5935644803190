@font-face {
  font-family: ar-bonnie;
  src: local("ar-bonnie"),
       url("/arBonnie.ttf");
  font-size: 24px;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-image: url('https://images.ctfassets.net/j9eel39hrxng/6p7RKWEXbG2o0ccUiQoiky/3c56f88663e603637e00c176e06f0c96/IMG_1543.jpg?fm=png&w=500');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  padding-bottom: 50px;
  max-width: 100%;
}

#nav ul {
  display: flex;
  justify-content: space-around;
  height: 54px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: #000;
  background-color: #fff;
}

#nav li {
  font-family: ar-bonnie;
  font-size: 24px;
  height: 100%;
  float: left;
  color: black;
  display: flex;
  justify-content:center;
  align-content:center;
  flex-direction:column;
  width: 100px;
}

li a {
  display: block;
  color: black;
  line-height: initial;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.nav {
  font-family: ar-bonnie;
  text-decoration: none;
  color: black;
}

/* Change the link color to #111 (black) on hover */
li a:hover {
  background-color: lightgrey;
  text-decoration: none;
  color: black;
}

#albums, #gallery, #events, #shop {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

#albums, #events, #shop {
  margin-top: 30px;
}


.album, .gallery, .event, .merch {
  text-align: center;
  width: 30%;
  margin-bottom: 30px;
  margin-right: 30px;
}

.album img, .gallery img {
  width: 90%;
}

.event {
  flex: 1;
}

.event img {
  height: 100%;
}

.container {
  margin: 0px auto;
  width: 970px;
}

.heading {
  text-decoration: none;
  font-family: ar-bonnie;
  color: white;
  text-align: center;
  font-size: 70px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.heading a {
  color: white;
  text-decoration: none;
}

#store {
  margin: 100px;
  text-align: center;
  font-size: 64px;
}

#album {
  display: flex;
  margin-top: 20px;
}

#bc-player iframe {
  flex-grow: 1;
  border: 0;
  width: 100%;
  height: 450px;
}

.thumbs {
  display: inline;
  float: left;
  margin-left: 20px;
}

.thumbs div {
  cursor: pointer;
}

.thumbs img {
  width: 100px;
  cursor: pointer;
}

.cover-selected {
  width: 450px;
  margin-left: 20px;
}

.covers img {
  border: 2px whitesmoke solid;
}

label {
  width: 100px;
  vertical-align: top;
}

input, textarea {
  width: 200px;
  border: gray 1px solid;
  resize: vertical;
}

textarea {
  height: 100px;
}

#verify {
  width: 50%;
  display: inline;
  float: left;
  height: 25px;
}

#contact-spacer {
  width: 100%;
  height: 20px;
  display: flex;
}

#submit {
  width: 50%;
  height: 25px;
  font-size: 24px;
  line-height: 22px;
}

#comments {
  width: 100%;
}

.percent-one-half {
  display: inline;
  width: 50%;
  float: left;
}

.percent-one-half input {
  width: 100%;
}

.clean {
  width: 100%;
}

#forms {
  padding: 0px;
  display: flex;
  color: black;
}

#contactform {
  flex-grow: 1;
}



/* CUSTOMIZE THE CAROUSEL
-------------------------------------------------- */

/* Carousel base class */
.carousel {
  height: 500px;
  /*margin-bottom: 60px;*/
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 10;
}

/* Declare heights because of positioning of img element */
.carousel .item {
  height: 500px;
  background-color: #777;
}
.carousel-inner > .item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
  height: 500px;
}

.carousel-indicators {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  left: 0;
}

.invisble {
  visibility: hidden;
}

.blurred {
  filter: blur(10px);
}


/* MARKETING CONTENT
-------------------------------------------------- */

/* Center align the text within the three columns below the carousel */
.marketing .col-lg-4 {
  margin-bottom: 20px;
  text-align: center;
}
.marketing h2 {
  font-weight: normal;
}
.marketing .col-lg-4 p {
  margin-right: 10px;
  margin-left: 10px;
}


.carousel {
  margin-top: 20px;
}

.carousel-inner > .item > img {
  position: relative;
  top: 0;
  margin: 0px auto;
  left: 0;
  min-width: 0% !important;
  height: 500px;
  text-align: center;
}
.item {
  background-color: black !important;
}
.carousel-caption {
  /*background-color: rgba(200, 200, 200, 0.6);
  border-radius: 20px;
  margin: 20px;
  margin-bottom: 50px;
  padding-left: 20px;
  padding-right: 20px;*/
  text-shadow: 1px 1px 1px black;
}
#aboutrizzy {
  font-size: 16px;
}
#li-about {
    background: none;
    color: white;
    border: none;
    text-shadow: 1px 1px 1px black;
}

.upcoming, .album-heading {
  width: 50%;
  margin: 0px auto;
  text-align: center;
}

.upcoming {
  min-height: 100px;
  clear: both;
  text-align: center;
}

.upcoming li {
  color: black;
}

.bonnie {
  font-family: ar-bonnie;
  font-size: 24px;
}

.sc-embed {
  display: flex;
  justify-content: space-around;
}

.sc-embed div {
  padding: 15px;
  flex-grow: 1
}

.list-group-item {
  text-align: left;
  width: 100%;
}

.about h4 {
  font-size: 32px;
  text-align: center;
}

audio {
  visibility: hidden;
}

#mySlides {
  margin-top: 30px;
}

.mySlide {
  height: 500px;
  margin: 0 auto;
}

.mySlideCaption {
  top: 250px;
  position: absolute;
  width: 500px;
  font-size: 18px;
  margin-left: 230px;
  text-shadow: 1px 1px 1px black;
}

.hidden {
  visibility: hidden;
}

.eventName {
  display: inline-block;
  max-width: 70%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.eventDate {
  display: inline-block;
  float: right;
}

.merch-title {
  font-size: 16px;
}

.merch {
  /*cursor: pointer;*/
}

.merch:hover {
  /*color: lightgrey;*/
  /*opacity: .9;*/
}

#tracks {
  position: absolute;
  right: 0px;
  width: 250px;
  bottom: 50px;
  max-height: 500px;
  transition-property: max-height visibility;
  transition-duration: .5s;
  transition-timing-function: linear;
  background-color: black;
  visibility: collapse;
  overflow-y: scroll;
}

span+#library, #controls > span, #progress {
  cursor: pointer;
}

@media (max-width: 400px) {
  .shop-banner {
    font-size: 24px !important;
  }
  #events, #albums {
    display: block;
  }
  .container {
    width: 100%;
  }
  #nav li {
    font-size: 16px;
  }
  .event, .album {
    width:100%;
  }
  #shop {
    justify-content: center;
  }
  .merch {
    width: 40%;
  }
  .form {
    flex-grow: 1;
  }
  .slick-slide img {
  	width: 100%;
  	height: auto;
  }
  .upcoming {
  	width: 100%;
    font-size: 20px !important;
  }
  .sc-embed {
  	display: block;
  }
  #library {
    font-size: 24px !important;
  }
  .bonnie {
  	font-size: 32px;
  }
  .album-heading {
  	width: 100%;
  }
  .hide-small {
    display: none;
  }
  .social-icons {
    display: none;
  }
  #forms div {
    padding: 0px;
  }
  #album {
    flex-direction: column;
  }
  #bc-player {
    width: 100%;
    margin-bottom: 20px
  }
  .thumbs {
    display: flex;
    flex-direction: row;
  }
  .covers, .thumbs {
    padding: 0px;
    margin: 0px;
  }
  .covers img {
    padding: 0px;
    width: 100%;
    margin: 0px;
  }
  .thumbs img {
    height: 100px;
    width: auto;
  }
  .mySlideCaption {
    top: 100px;
    position: absolute;
    width: 300px;
    font-size: 13px;
    margin-left: 50px;
    text-shadow: 1px 1px 1px black;
  }
  .mySlideCaption .btn {
    font-size: 22px;
  }
  .eventName {
    max-width: 60%;
  }
  .accordion__title {
    padding: inherit;
  }
  #tracks {
    max-height: calc(100vh*.9);
  }
}

.shop {
  display: flex;
  flex-direction: row;
  justify-content: start;
  flex-wrap: wrap;
}

.merch {
  /*margin-right: 40px;*/
}

#gallery .MuiSvgIcon-root {
  height: 100%;
}

.MuiAlert-root {
  font-size: inherit !important;
}
